<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-break-point="960"
    app
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini">{{ orgname }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in isLocation">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- If location selected in blocks  -->
      <!-- <template v-for="(item, i) in computedMapboard">
        <base-item
          :key="`map-${i}`"
          :item="item"
        />
      </template> -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import { mapState } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      items: [
        // ===== Dashboard =====
        {
          active: true,
          icon: 'mdi-view-dashboard',
          title: 'sections.dashboard',
          to: '/dashboard/main',
        },

        // ===== Monitoring Tools =====
        {
          active: true,
          group: '/dashboard/monitor',
          icon: 'mdi-clipboard-pulse-outline',
          title: 'sections.data-monitoring',
          children: [
            {
              title: 'monitoring.tables',
              to: 'mtables',
            },
            // {
            //   title: 'monitoring.charts',
            //   to: 'mcharts',
            // },
            {
              title: 'monitoring.dstat',
              to: 'mstats',
            },
          ],
        },

        // ===== Mapboard =====
        {
          active: false,
          icon: 'mdi-map',
          title: 'sections.mapboard',
          to: '/dashboard/maps/mapboard',
        },

        // // ===== Analysis =====
        // {
        //   active: true,
        //   group: '/dashboard/analysis',
        //   icon: 'mdi-chart-bell-curve',
        //   title: 'sections.data-analysis',
        //   children: [
        //     {
        //       title: 'analysis.pivot',
        //       to: 'pivot',
        //     },
        //     {
        //       title: 'analysis.dstat',
        //       to: 'descriptive',
        //     },
        //   ],
        // },

        // ===== Processings =====
        {
          active: true,
          group: '/dashboard/processing',
          icon: 'mdi-database-search',
          title: 'sections.data-processing',
          children: [
            // {
            //   title: 'exclude-filters',
            //   to: 'exclude',
            // },
            {
              title: 'process.qcontrol',
              to: 'check',
            },
          ],
        },

        // ===== Reporting =====
        {
          active: false,
          // group: '/dashboard/reports',
          icon: 'mdi-file-document',
          title: 'reports.reports',
          to: '/dashboard/reports',
          // children: [
          //   {
          //     title: 'analysis.pivot',
          //     to: 'pivot',
          //   },
          //   {
          //     title: 'analysis.dstat',
          //     to: 'descriptive',
          //   },
          // ],
        },

        // ===== Data Export =====
        {
          active: true,
          icon: 'mdi-database-export',
          title: 'sections.data-export',
          to: '/dashboard/export',
        },

        // ===== Form Settings =====
        {
          active: true,
          icon: 'mdi-cog',
          title: 'sections.settings',
          to: '/dashboard/settings',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),

      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },

      computedItems () {
        // return this.items.map(this.mapItem)
        return this.items.filter(i => i.active === true).map(this.mapItem)
      },

      isLocation () {
        // const dBlock = this.$store.state.main.dsb.dBlocks
        // console.log(this.$store.state.main.dsb.dBlocks.filter(x => x.pack === 'oclocation'))
        if (this.$store.state.main.dsb.dBlocks.filter(x => x.pack === 'oclocation')[0].selected) {
          const sections = JSON.parse(JSON.stringify(this.items))
          sections[this.items.findIndex(x => x.title === 'sections.mapboard')].active = true
          // console.log(sections)
          return sections.filter(i => i.active === true).map(this.mapItem)
        } else {
          // console.log('original item')
          return this.items.filter(i => i.active === true).map(this.mapItem)
        }
      },

      // computedMapboard () {
      //   if (this.mapactive) {
      //     return this.mapboard.map(this.mapItem)
      //   } else {
      //     return []
      //   }
      // },

      // formid () {
      //   const formid = this.$store.state.main.afid
      //   if (formid.length > 20) {
      //     return formid.substr(0, 17) + '...'
      //   } else {
      //     return formid
      //   }
      // },

      orgname () {
        return this.$store.state.bend.organization.title
      },

    },

    // watch: {
    //   '$vuetify.breakpoint.smAndDown' (val) {
    //     this.$emit('update:expandOnHover', !val)
    //   },
    // },

    created () {
      // this.isLocation()
      // this.getdLocationBlock()
    },

    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },

      // async getdLocationBlock () {
      //   const ocpuPack = this.$store.state.ocpu.ocpuPack
      //   const main = this.$store.state.main
      //   await this.$httpOcpu.post(`/${ocpuPack}/R/ocgetblocks/json`, {
      //     orgid: main.orgid,
      //     prjid: main.ap.prjid,
      //     formid: main.afid,
      //     bname: 'dBlocks',
      //   }).then(
      //     response => {
      //       // console.log(response)
      //       if (response.data.length > 0) {
      //         // Parse json string respons)e from ocpu
      //         const data = JSON.parse(response.data[0])
      //         // console.log(data)
      //         const active = data.filter(f => f.pack === 'oclocation' && f.selected === true)
      //         // console.log(active)
      //         if (active.length > 0) {
      //           this.mapactive = true
      //         }
      //       }
      //     })
      // },

    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
